import { createGlobalStyle, css, ThemeContext } from 'styled-components';
import { useContext, useEffect } from 'react';

const GlobalStylesContainer = createGlobalStyle`
    body,
    html,
    #root {
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: white;
    }

    #root {
        min-height: 620px;
    }

    * {
        box-sizing: border-box;
        font-family: ${props => props.theme.fontFamily.name}, sans-serif;
        scrollbar-width: thin;
        scrollbar-color: ${(props) => props.theme.scrollAlternative} #fff;
    }
	
	${props => props.theme.fontFamily.allTextUppercase && css`
		* {
			text-transform: uppercase;
		}
	`}
	
    input[type='range'] {
        background-color: transparent;
        cursor: pointer;
        max-width: 140px;
		appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
    }

    @media screen and (max-width: 768px) {
        /*Preventing iOS Textbox Auto Zooming*/
        input,
        textarea {
            font-size: 16.1px !important;
        }
    }
`

const useStyle = (id: string, url: string) => {
	useEffect(() => {
		if (document.getElementById(id)) {
			return
		}
		const link = document.createElement('link');
		link.id = id;
		link.rel = 'stylesheet';
		link.href = url;
		document.head.appendChild(link);
	}, [id, url]);
}

// style must be imported using the hook to avoid this issue https://styled-components.com/docs/faqs#note-regarding-css-import-and-createglobalstyle
// when solved, the @import keyword could be used instead
const useFontFamily = () => {
	const theme = useContext(ThemeContext);
	if (!theme) {
		throw new Error('GlobalStyles must be used inside a ThemeProvider');
	}
	useStyle(`theme-font-${theme.fontFamily.name}`, theme.fontFamily.styleUrl);
}

const GlobalStyles = () => {
	useFontFamily();
	return <GlobalStylesContainer />
}

export default GlobalStyles;
