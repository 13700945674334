// import original module declarations
import 'styled-components';
import { DefaultTheme } from 'styled-components';

// and extend them!
declare module 'styled-components' {
	export interface DefaultTheme {
		fontFamily: {
			name: string;
			styleUrl: string;
			allTextUppercase: boolean;
		}
		fonts: {
			large100bold: string;
			large200bold: string;
			large300bold: string;
			large500bold: string;
			largeBody: string;
			large200: string;
			smallBodyBold: string;
		};
		colors: {
			primary: string;
			primaryState: string;
			accent: string;
			accentState: string;

			textOverPrimary: string;
			textOverAccent: string;
			textOnlyAccent: string;
			textMain: string;
			textSecondary: string;
			textFooter: string;
			textDisabled: string;

			backgroundMain: string;
			backgroundSecondary: string;
			backgroundFooter: string;
			background3d: string;
			backgroundPreview: string;

			borders: string;

			iconMain: string;
			iconSecondary: string;
			iconSecondaryState: string;
			iconDisabled: string;

			error: string;
			errorText: string;
			errorLight: string;
			success: string;

			scrollAlternative: string;
		};
		spacing: {
			horizontalPadderNumber: number;
			horizontalPadderMobileNumber: number;
			horizontalPadder: string;
			horizontalPadderMobile: string;
		};
		measures: {
			borderRadius: string;
		};
		panels: {
			borderWidth: string;
			shadows: string;
		};
		dialogs: {
			backdrop: string;
			shadows: string;
		};
		pricing: {
			showPrice: boolean;
			priceInfo?: string;
		};
		zoom: {
			zoomPercentageIncrement: number;
		};
		preview3d: {
			allowPreview3DExpandOnContainerClick: boolean;
			showResize3DPreview: boolean;
			showResize3DHeight: number;
			showResize3DWidth: number;
		};
		general: {
			measurementUnit: string;
			showUnselectedElementsDimensions: boolean;
			showTotalSizeOfCurrentSide: boolean;
			isUndoRedoEnabled: boolean;
		};
	}
}

const horizontalPadderNumber = 22;
const horizontalPadderMobileNumber = 20;

export const createThemeFont = (fontFamily: string): DefaultTheme['fonts'] => {
	return {
		large100bold: `normal normal 700 14px/20px ${fontFamily}`,
		large200bold: `normal normal 700 16px/24px ${fontFamily}`,
		large300bold: `normal normal 700 20px/28px ${fontFamily}`,
		large500bold: `normal normal 700 32px/40px ${fontFamily}`,
		largeBody: `normal normal 400 14px/20px ${fontFamily}`,
		large200: `normal normal 400 16px/24px ${fontFamily}`,
		smallBodyBold: `normal normal 700 15px/20px ${fontFamily}`,
	}
}

export const defaultTheme: DefaultTheme = {
	fontFamily: {
		name: 'Inter',
		styleUrl: `${process.env.PUBLIC_URL}/assets/fonts/inter/style.css`,
		allTextUppercase: false,
	},
	fonts: createThemeFont('Inter'),
	colors: {
		primary: '#0073D3',
		primaryState: '#128DF3',
		accent: '#F46200',
		accentState: '#F79F64',

		textOverPrimary: '#FFFFFF',
		textOverAccent: '#FFFFFF',
		textOnlyAccent: '#E65C00',
		textMain: '#000000',
		textSecondary: '#000000',
		textFooter: '#000000',
		textDisabled: '#8FA4AE',

		backgroundMain: '#FFFFFF',
		backgroundSecondary: '#F1F2F3',
		backgroundFooter: '#FFFFFF',
		background3d: '#F1F2F3',
		backgroundPreview: '#DFDFE0',

		borders: '#DBE2E6',

		iconMain: '#1A1C1D',
		iconSecondary: '#1A1C1D',
		iconSecondaryState: '#0073D3',
		iconDisabled: '#BABEC3',

		error: '#DE1C22',
		errorText: '#9F1B1F',
		errorLight: '#FFF4F4',
		success: '#086343',

		scrollAlternative: '#8fa4ae',
	},
	spacing: {
		horizontalPadderNumber,
		horizontalPadderMobileNumber,
		horizontalPadder: `${horizontalPadderNumber}px`,
		horizontalPadderMobile: `${horizontalPadderMobileNumber}px`,
	},
	measures: {
		borderRadius: '4px',
	},
	panels: {
		borderWidth: '1px 1px 5px 1px',
		shadows: '0 0 1px rgba(0, 0, 0, 0.25), 0 2px 1px rgba(0, 0, 0, 0.05)',
	},
	dialogs: {
		backdrop: 'rgba(0, 0, 0, 0.3)',
		shadows: '0 4px 20px rgba(0, 0, 0, 0.15), 0 0 3px rgba(0, 0, 0, 0.1)',
	},
	pricing: {
		showPrice: true,
	},
	zoom: {
		zoomPercentageIncrement: 100,
	},
	preview3d: {
		allowPreview3DExpandOnContainerClick: true,
		showResize3DPreview: true,
		showResize3DHeight: 200,
		showResize3DWidth: 220,
	},
	general: {
		measurementUnit: 'cm',
		showTotalSizeOfCurrentSide: true,
		showUnselectedElementsDimensions: true,
		isUndoRedoEnabled: true,
	}
};

type DeepPartial<T> = T extends object ? {
	[P in keyof T]?: DeepPartial<T[P]>;
} : T;

export const createTheme = (overrides: DeepPartial<DefaultTheme>): DefaultTheme => {
	return {
		...defaultTheme,
		fontFamily: {
			...defaultTheme.fontFamily,
			...overrides.fontFamily,
		},
		fonts: {
			...defaultTheme.fonts,
			...overrides.fonts,
		},
		colors: {
			...defaultTheme.colors,
			...overrides.colors,
		},
		spacing: {
			...defaultTheme.spacing,
			...overrides.spacing,
		},
		measures: {
			...defaultTheme.measures,
			...overrides.measures,
		},
		panels: {
			...defaultTheme.panels,
			...overrides.panels,
		},
		dialogs: {
			...defaultTheme.dialogs,
			...overrides.dialogs,
		},
		pricing: {
			...defaultTheme.pricing,
			...overrides.pricing,
		},
		zoom: {
			...defaultTheme.zoom,
			...overrides.zoom,
		},
		preview3d: {
			...defaultTheme.preview3d,
			...overrides.preview3d,
		},
		general: {
			...defaultTheme.general,
			...overrides.general,
		},
	};
}
