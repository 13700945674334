import { InterfaceSettings } from '@zakeke/zakeke-customizer-react';
import { DefaultTheme } from 'styled-components';
import { createTheme, createThemeFont } from './theme';

export type InterfaceSettingsTheme = Partial<{
	fontFamily: DefaultTheme['fontFamily'];
	colors: DefaultTheme['colors'];
}>
const createThemeFromZakekeInterfaceSettings = (settings: InterfaceSettings<InterfaceSettingsTheme>): DefaultTheme => {
	return createTheme({
		fontFamily: settings.theme.fontFamily,
		fonts: settings.theme.fontFamily ? createThemeFont(settings.theme.fontFamily.name) : undefined,
		colors: settings.theme.colors,
		pricing: {
			showPrice: settings.general.showPrice,
			priceInfo: settings.general.priceText,
		},
		zoom: {
			zoomPercentageIncrement: settings.general.zoomPercentageIncrement,
		},
		preview3d: {
			allowPreview3DExpandOnContainerClick: settings.general.allowPreview3DExpandOnContainerClick,
			showResize3DPreview: settings.general.showResize3DPreview,
			showResize3DHeight: settings.general.height3DPreview,
			showResize3DWidth: settings.general.width3DPreview,
		},
		general: {
			measurementUnit: settings.general.unitOfMeasurement,
			showUnselectedElementsDimensions: settings.general.showUnselectedElementsDimensions,
			showTotalSizeOfCurrentSide: settings.general.showTotalSizeOfCurrentSide,
			isUndoRedoEnabled: settings.general.canUndoRedo,
		}
	})
}

export default createThemeFromZakekeInterfaceSettings;
